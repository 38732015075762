<template>
  <div>
    <div
      style="position: relative;"
    >
      <div
        @click="active ? $refs.realFileInput.click() : null"
        @mouseover="hover = active"
        @mouseleave="hover = false"
      >
        <v-btn
          v-if="!currentImage || hover"
          v-ripple="false"
          fab
          outlined
          dashed
          light
          :class="!active && 'v-btn__inactive'"
          :width="customSize || 36"
          :height="customSize || 36"
          color="primary"
        >
          <v-icon dark>
            {{ customIcon || 'mdi-plus' }}
          </v-icon>
        </v-btn>
        <v-avatar
          v-else
          :size="customSize || 36"
          :rounded="isSquare"
          :color="!active ? $vuetify.theme.themes.light.inactive : null"
        >
          <img :src="currentImage">
        </v-avatar>
      </div>
      <input
        ref="realFileInput"
        type="file"
        accept="image/png, image/jpeg"
        style="display: none;"
        @change="cropImage($event.target.files[0])"
        @click="$event.target.value = null"
      >
    </div>
    <v-dialog
      v-model="cropperDialog"
      max-width="400px"
      max-height="400px"
    >
      <v-card class="pa-8">
        <v-card-title class="pa-0 mb-4" v-text="$t('formFields.cropImage')" />
        <cropper
          ref="cropper"
          class="cropper"
          :src="imageToCrop"
          :min-height="10"
          :min-width="10"
          :stencil-props="{
            aspectRatio: width / height,
            handlers: {
              eastNorth: true,
              north: false,
              westNorth: true,
              west: false,
              westSouth: true,
              south: false,
              eastSouth: true,
              east: false,
            }
          }"
        />
        <v-card-actions class="pa-0 mt-8">
          <v-btn
            color="primary"
            small
            block
            width="120"
            @click="saveImageFile"
            v-text="$t('common.save')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import localeUtils from '@/utils/mixins/localeUtils'
import { getFileUrl } from '@/utils/fileReader'

export default {
  name: 'AvatarField',
  components: {
    Cropper,
  },
  mixins: [localeUtils],
  props: {
    value: {
      type: [String, File],
      default: null,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    customSize: {
      type: String,
      default: null,
    },
    customIcon: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    active: {
      type: Boolean,
      default: true,
    },
    isSquare: { type: Boolean, default: false },
  },
  data() {
    return {
      error: this.errorMessages,
      cropperDialog: false,
      currentImage: this.value,
      imageToCrop: null,
      imageName: null,
      hover: false,
    }
  },
  watch: {
    async value(newValue, oldValue) {
      if (newValue !== oldValue && typeof newValue !== 'string') {
        this.currentImage = await getFileUrl(newValue)
        this.$emit('updateAvatar', this.currentImage)
      }
    },
  },
  methods: {
    async cropImage(imageFile) {
      this.$store.commit('loader/show')
      this.imageName = imageFile.name
      const imageBase64 = await getFileUrl(imageFile)
      this.imageToCrop = imageBase64
      this.cropperDialog = true
      this.$nextTick(() => {
        this.$refs.cropper.setCoordinates((coordinates, imageSize) => ({ width: imageSize.width, height: imageSize.height }))
      })
      setTimeout(() => {
        this.$store.commit('loader/hide')
      }, 500)
    },
    async saveImageFile() {
      const { canvas } = this.$refs.cropper.getResult()
      if (!canvas) return
      this.$store.commit('loader/show')
      const resizedCanvas = document.createElement('canvas')
      resizedCanvas.width = this.width
      resizedCanvas.height = this.height
      resizedCanvas.getContext('2d').drawImage(canvas, 0, 0, this.width, this.height)
      const imageType = this.hasTransparency(resizedCanvas) ? 'image/png' : 'image/jpeg'
      const imageBlob = await new Promise(resolve => {
        canvas.toBlob(blob => resolve(blob), imageType, 0.8)
      })
      const imageFile = new File([imageBlob], this.imageName)
      this.$emit('input', imageFile)
      this.currentImage = canvas.toDataURL()
      this.cropperDialog = false
      this.$store.dispatch('alert/openAlertBox', ['alertSuccess', 'Success'])
      this.$store.commit('loader/hide')
    },
    hasTransparency(canvas) {
      const context = canvas.getContext('2d')
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data
      const checkPoints = [...Array(Math.floor((imageData.length + 1) / 4))].map((v, i) => 4 * i + 3)
      return checkPoints.some(point => imageData[point] < 255)
    },
  },
}
</script>

<style scoped>
.v-btn__inactive:before {
  opacity: 0 !important;
}

.v-btn--outlined {
  border: thin dashed currentColor !important;
}

.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
