var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{on:{"click":function($event){_vm.active ? _vm.$refs.realFileInput.click() : null},"mouseover":function($event){_vm.hover = _vm.active},"mouseleave":function($event){_vm.hover = false}}},[(!_vm.currentImage || _vm.hover)?_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],class:!_vm.active && 'v-btn__inactive',attrs:{"fab":"","outlined":"","dashed":"","light":"","width":_vm.customSize || 36,"height":_vm.customSize || 36,"color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.customIcon || 'mdi-plus')+" ")])],1):_c('v-avatar',{attrs:{"size":_vm.customSize || 36,"rounded":_vm.isSquare,"color":!_vm.active ? _vm.$vuetify.theme.themes.light.inactive : null}},[_c('img',{attrs:{"src":_vm.currentImage}})])],1),_c('input',{ref:"realFileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/png, image/jpeg"},on:{"change":function($event){return _vm.cropImage($event.target.files[0])},"click":function($event){$event.target.value = null}}})]),_c('v-dialog',{attrs:{"max-width":"400px","max-height":"400px"},model:{value:(_vm.cropperDialog),callback:function ($$v) {_vm.cropperDialog=$$v},expression:"cropperDialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('v-card-title',{staticClass:"pa-0 mb-4",domProps:{"textContent":_vm._s(_vm.$t('formFields.cropImage'))}}),_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.imageToCrop,"min-height":10,"min-width":10,"stencil-props":{
          aspectRatio: _vm.width / _vm.height,
          handlers: {
            eastNorth: true,
            north: false,
            westNorth: true,
            west: false,
            westSouth: true,
            south: false,
            eastSouth: true,
            east: false,
          }
        }}}),_c('v-card-actions',{staticClass:"pa-0 mt-8"},[_c('v-btn',{attrs:{"color":"primary","small":"","block":"","width":"120"},domProps:{"textContent":_vm._s(_vm.$t('common.save'))},on:{"click":_vm.saveImageFile}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }